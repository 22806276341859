$font:375/10rem;
  .root{
    :global{
      .time_left{
        display: inline-block;
        width: 100%;
        p{
            font-size: 16/$font;
            line-height: 16/$font;
            font-weight: bold;
            margin-bottom: 10/$font;
        }
        span{
            font-size: 16/$font;
            line-height: 16/$font;
            color: #878787;
        }
      }
    }
  }