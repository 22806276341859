$font:375/10rem;
.root{
    :global{
        height: 100%;
        background: #edeef0;
        iframe{
            margin: 0;
            padding: 0;
            border: none;
        }
    }
}