$font:375/10rem;
.root{
    :global{
        background: #edeef0;
        .base_margin_bottom{
            margin-bottom: 16/$font;
            &.the_meeting_password{
                padding: 0 18/$font;
                background-color: #fff;
                input{
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: none;
                    font-size: 17/$font;
                    padding: 5/$font 0;
                }
            }
            &.the_meeting_record{
                padding: 0 18/$font;
                background-color: #fff;
                .adm-list-body{
                    border: none;
                    .adm-collapse-panel-content .adm-list-item-content-main{
                        color: #333333;
                        font-size: 16/$font;
                    }
                    
                }
            }
        }
        .theme{
            background: #fff;
            padding: 0 18/$font;
            height: 60/$font;
            position: relative;
            display: flex;
            align-items: center;
            font-size: 16/$font;
            .meeting_theme_toast{
                .adm-toast-main{
                    max-width: none;
                }
            }
            .adm-input-element{
                height: 48/$font;
                font-size: 17/$font;
                font-weight: bold;
            }
            // .i-icon-monitor-one{
            //     position: absolute;
            //     right:10/$font;
            //     top: 0;
            //     bottom: 0;
            //     margin: auto;
            //     display: flex;
            //     align-items: center;
            // }
        }
        .time{
            display: flex;
            position: relative;
            background: #fff;
            padding: 0 18/$font;
            height: 60/$font;
            padding-top: 12/$font;
            .date-picker-box{
                width: 45%;
                &.date-picker-right{
                    padding-left: 50/$font;
                }
            }
            .i-icon-right{
                width: 10%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .base_style{
            background: #fff;
            padding: 0 18/$font;
            height: 40/$font;
            line-height: 40/$font;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16/$font;
            div{
                display: flex;
                align-items: center;
                color: #9b9b9b;
            }
            &.disabled{
                span{
                    color: #999;
                }
            }
            &.save{
                background: #edeef0;
                span{
                    width: 100%;
                    background: #1675fd;
                    color: #fff;
                    text-align: center;
                    border-radius: 4/$font;
                }
            }
            &.meeting_type{
                .adm-space{
                    flex-direction: inherit;
                    .adm-space-item{
                        margin-bottom: 0;
                        path{
                            color: #fff !important;
                        }
                        &:nth-child(1){
                            margin-right: 25/$font;
                        }
                    }
                }
            }
        }
    }
}