$font:1080/10rem;
.root{
  margin-bottom: 36/$font;
  :global{
      .item_left{
          float: left;
          width: 140/$font;
          text-align: center;
          padding-top: 45/$font;
          font-size: 58/$font;
          color: #000;
          font-weight:500;
          margin-right: 50/$font;
          .month{
              color: #999;
              font-size: 50/$font;
          }
          .date{
              font-size: 80/$font;
          }
      }
      .item_right{
          overflow: hidden;
          // background: #F8F8F8;
          padding: 57/$font;
          padding-right: 63/$font;
          border-radius: 10/$font;
          &.zoome_back{
            background: #b9efdd;
          }
          &.teng_back{
            background: #f3d7d7;
          }
          p:nth-child(1){
              display: flex;
              justify-content:space-between;
              align-items: center;
              height: 48/$font;
              line-height: 48/$font;
              margin-bottom: 20/$font;
              color: #000;
              span:nth-child(1){
                  font-size: 48/$font;
                  font-weight: bolder;
              }
              span:nth-child(2){
                  font-size: 40/$font;
                  .i-icon-waves-left{
                    display: none;
                  }
              }
              .yellow{
                color: #DC995C;
              }
              .green{
                color: #32BE32;
                .i-icon-waves-left{
                    display: inline-block !important;
                    margin-right: 8/$font;
                  }
              }
              .gray{
                color: gray;
              }
          }
          p:nth-child(2){
              height: 35/$font;
              line-height: 35/$font;
              color: #000;
              font-size: 35/$font;
              margin-bottom: 35/$font;
          }
          p:nth-child(3){
              height: 35/$font;
              line-height: 35/$font;
              color: #68696D;
              font-size: 35/$font;
              position: relative;
              margin-bottom: 35/$font;
              span:nth-child(2){
                position: absolute;
                right: 0;
                top: -40/$font;
                color: #fff;
                background: #6493EB;
                padding: 20/$font;
                border-radius: 10/$font;
                display: none;
              }
              
          }
          p:nth-child(4){
            height: 35/$font;
            line-height: 35/$font;
            color: #68696D;
            font-size: 35/$font;
            position: relative;
        }
      }
      .show{
        display: block !important;
      }
    }
}