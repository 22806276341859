$font:1080/10rem;
.root{
    :global{
        position: relative;
        height: 100%;
        font-size:small;
        box-sizing: border-box;
        padding: 0 57.6/$font;
        background: #edeef0;
        .hidden{
            display: none;
        }
        .show{
            display:block;
        }
        .header{
            display: flex;
            justify-content: space-between;
            height: 43/$font;
            .header_left{
                display: inline-block;
                width: 33%;
                display: flex;
                align-items: center;
                position: relative;
                img{
                display: inline-block;
                width: 43/$font;
                height: 43/$font;
                border-radius: 50%;
                }
                .left_ball{
                font-size: 34.5/$font;
                }
                .left_label{
                font-size: 34.5/$font;
                margin-left: 34.5/$font;
                margin-right: 34.5/$font;
                color: #363739;
                }
                .left_triangle{
                position: relative;
                top: 8.64/$font;
                width: 0;
                height: 0;
                line-height: 0;
                font-size: 0;
                border-top: 17.28/$font solid #5e5f61;
                border-bottom: 17.28/$font solid transparent;
                border-left: 11.52/$font solid transparent;
                border-right: 11.52/$font solid transparent;
                }
                .left_select{
                display: none !important;
                position: absolute;
                    width: 86.4/$font;
                    font-size: 0.32rem;
                    display: flex;
                    flex-direction: column;
                    width: 82%;
                    background: #fff;
                    top: 37/37rem;
                    top: 0.89rem;
                    border-radius: 6px;
                    text-align: center;
                    padding: 0.26rem 0;
                    span:nth-child(1){
                    margin-bottom: 57.6/$font;
                    }
                }
            }
            .header_cent{
                display: inline-block;
                width: 33%;
                display: flex;
                align-items: center;
                justify-content: center;
                .i-icon-difference-set{
                    transform: rotate(45deg);
                }
                .cent_title{
                    font-size: 34.56/$font;
                    color: #969799;
                    margin-left: 14.4/$font;
                }
            }
            .header_right{
                display: inline-block;
                width: 33%;
                display: flex;
                justify-content:flex-end;
                img{
                    display: block;
                    width: 80.64/$font;
                    height: 80.64/$font;
                    border-radius: 50%;
                }
            }
        }
        .body{
            margin-top: 43/$font;
            background: #fff;
            border-top-left-radius:25/$font;
            border-bottom-left-radius: 25/$font;
            border-bottom-right-radius: 25/$font;
            padding-top: 108/$font;
            position: relative;
        .i-icon-arrow-left{
            position: absolute;
            left: 3px;
            top: 3px;
        }
        .i-icon-two-dimensional-code-one{
            position: absolute;
            right: 0;
            top: 0;
            -webkit-tap-highlight-color:rgba(0,0,0,0);
        }
        .body_top{
            
            .header_cent{
                width: 100%;
                display: inline-block;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 122/$font;
            .i-icon-difference-set{
                transform:rotate(45deg);
            }
            .cent_title{
                font-size: 50/$font;
                line-height: 50/$font;
                color: #000;
                font-weight: bolder;
                margin-left: 15/$font;
            }
            }
            
            
        }
        
        .body_bottom{
            // padding-top: 40/$font;
            // padding-bottom: 60/$font;
            text-align: center;
            min-height: 120/$font;
            span{
                color: #989898;
                font-size: 36/$font;
                line-height: 36/$font;
            }
        }
        }
        .foot{
            position: absolute;
            left: 0;
            bottom:0/$font;
            width: 100%;
            box-sizing: border-box;
            padding: 0 57.6/$font;
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);
        div{
            height: 116/$font;
            border: 1px #cccccc solid;
            text-align: center;
            line-height: 116/$font;
            background: #fff;
            margin-bottom: 34/$font;
            &:nth-child(2){
                color: #fff;
                background: #2e88ff;
            }
        }

        p{
            margin: 0;
            color: #a0a1a3;
            font-size: 30/$font;
            text-align: center;
            span{
            color: #006ffc;
            }
        }
        }
        .dialog{
            display: none;
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(95, 95, 95, 0.37);
            z-index: 999;
            left: 0;
            top: 0;
        .dialog_box{
            padding-top: 0.6rem;
            padding-left: 0.69444rem;
            padding-right: 0.69444rem;
            display: flex;
            align-items: flex-end;
            /* justify-content: flex-end; */
            width: 100%;
            box-sizing: border-box;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            .i-icon-close-one{
            margin-bottom: 30/$font;
            -webkit-tap-highlight-color:rgba(0,0,0,0);
            }
            >div{
                width: 100%;
            }
        
            .dialog_box_header{
                display: flex;
                justify-content: flex-end;
                margin-bottom: 28/$font;
            span{
                width: 92/$font;
                height: 92/$font;
                border-radius: 50%;
                border:4/$font #fff solid;
                line-height: 92/$font;
                text-align: center;
                color: #fff;
            }
            }
            .dialog_box_body{
                background: #ffffff;
                border-radius: 40/$font;
                padding-top: 120/$font;
                position: relative;
            .box_body_qr_code{
                padding-top: 120/$font;
                padding-bottom: 150/$font;
                text-align: center;
                img{
                box-sizing: border-box;
                display: block;
                width: 444/$font;
                height: 444/$font;
                margin: 0 auto 40/$font;
                border: 1px #ccc solid;
                border-radius: 5/$font;
                padding: 4/$font;
                }
            }
            }
            .dialog_box_bottom{
            >div:nth-child(1){
                width: 264/$font;
                height: 98/$font;
                margin: 24/$font auto 35/$font;
                border: 1px #fff solid;
                border-radius: 10px;
                padding: 0 38/$font 0 20/$font;
                display: flex;
                align-items: center;
                justify-content: center;
                .i-icon-devices{
                margin-top: 8/$font;
                margin-right: 3/$font;
                }
                span{
                color: #fff;
                font-size: 30/$font;
                line-height: 55/$font;
                }
            }
            >div:nth-child(2){
                width: 264/$font;
                height: 98/$font;
                margin: 24/$font auto 35/$font;
                // border: 1px #fff solid;
                border-radius: 10px;
                padding: 0 38/$font 0 20/$font;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: #ffffff;
            }
            }
        }
        }
        .showDialog{
            display: block;
        }
        .shared_box{
            position:fixed;
            top:0;
            left:0;
            width:100vw;
            height:100vh;
            background:rgba(0, 0, 0, 0.7);
            display:none;
            z-index:20000;
            &.show_shared_box{
                display: block;
            }
            img{
                position:fixed;
                right: -3vw;
                top: 1vh;
                width: 50vw;
                height: 20vw;
                z-index: 999;
                object-fit: contain;
            }
        }
        .shared_box_two{
            position:fixed;
            top:0;
            left:0;
            width:100vw;
            height:100vh;
            background:#fff;
            display:none;
            z-index:20000;
            
            &.show_shared_box_two{
                display: block;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }
    }
}