$font:1242/10rem;
.root{
    :global{
        .app_header{
            position: fixed;
            right: 57.6/$font;
            top: 0;
            height: 74/$font;
            line-height: 74/$font;
            font-size: 44/$font;
            display: flex;
            justify-content: flex-end;
            color: #999;
            align-items: center;
            z-index: 2;
        }
        .app_content{
            padding-top: 74/$font;
            padding-bottom: 74/$font;
            .temporarily_no_data{
                position:absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                text-align: center;
                color: #999;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
            }
        }
    }
}