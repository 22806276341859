$font:1080/10rem;
  .root{
    :global{
      .body_top_title{
          font-size: 50/$font;
          line-height: 50/$font;
          text-align: center;
          margin-bottom: 74/$font;
      }
      .body_top_num_box{
        display: flex;
        justify-content: center;
        align-items:flex-end;
        margin-bottom: 46/$font;
        .body_top_num_div{
          display: flex;
          justify-content: center;
          align-items: flex-end;
          .body_top_num{
            list-style: none;
            font-size: 50/$font;
            line-height: 50/$font;
            display: flex;
            margin: 0;
            padding: 0;
            li{
              margin-right: 18/$font;
            }
            li:last-child{
              margin-right: 15/$font;
            }
          }
          .body_top_icon{
            width: 34/$font;
            height: 40/$font;
          }
        }
      }
    }
  }