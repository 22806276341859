$font:375/10rem;
.root{
    :global{
        height: 100%;
        background: fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}