$font:428/10rem;
.root{
    :global{
        height: 100%;
        background: #fff;
        padding: 15/$font 20/$font;
        .header_box{
            text-align: center;
            margin-bottom: 20/$font;
            p:nth-child(1){
                font-size: 17/$font;
            }
        }
        .content_box{
            .adm-list-item{
                padding-left: 0;
                .adm-list-item-content{
                    padding-right: 0;
                }
            }
        }
        .terms{
            vertical-align: -2px;
            margin-left: 5px;
        }
    }
}