$font:1080/10rem;
.root{
  :global{
    display: flex;
    justify-content: space-between;
    padding: 0 50/$font;
    margin-bottom: 60/$font;
    .top_time_box_left{
        display: flex;
        flex-direction: column;
        justify-content: center;
      P{
        margin: 0;
        padding: 0;
      }
      p:nth-child(1){
        font-size: 60/$font;
        line-height: 60/$font;
        color: #000;
        font-weight: bolder;
        margin-bottom: 26/$font;
        text-align: left;
      }
      p:nth-child(2){
        font-size: 23/$font;
        line-height: 23/$font;
        color: #000;
      }
    }
    .top_time_box_center{
      text-align: center;
      position: relative;
      div:nth-child(1){
        color: #e9954d;
        font-size: 30/$font;
        margin-bottom: 26/$font;
      }
      div:nth-child(2){
        margin-bottom: 25/$font;
        span{
          position: relative;
          font-size: 26/$font;
          background-color: #efefef;
          line-height: 26/$font;
          padding: 5/$font;
          display: inline-block;
        }
        span::after{
          content: '';
          position: absolute;
          width: 66/$font;
          height: 1px;
          background: #cccccc;
          left: -82/$font;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        span::before{
          content: '';
          position: absolute;
          width: 66/$font;
          height: 1px;
          background: #cccccc;
          right: -82/$font;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      
      
      div:nth-child(3){
        color: #969696;
        font-size: 26/$font;
      }
    }
    .top_time_box_right{
        display: flex;
        flex-direction: column;
        justify-content: center;
      P{
        margin: 0;
        padding: 0;
      }
      p:nth-child(1){
        font-size: 60/$font;
        line-height: 60/$font;
        color: #000;
        font-weight: bolder;
        margin-bottom: 26/$font;
        text-align: right;
      }
      p:nth-child(2){
        font-size: 23/$font;
        line-height: 23/$font;
        color: #000;
      }
    }
  }
}