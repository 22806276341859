$font:428/10rem;
.root{
    :global{
        height: 100%;
        background: #fff;
        .header{
            padding: 29/$font;
            // background: #aee1f2;
            .header_top{
                // border-bottom: 1px #ccc solid;
                height: 30/$font;
                font-size: 16/$font;
                font-weight: bolder;
            }
            .header_content{
                display: flex;
                border-top: 1px #eeeeee solid;
                border-bottom: 1px #eeeeee solid;
                padding: 20/$font 0;
                // .content_left,.content_right{
                //     flex: 1;
                // }
                .content_left{
                    flex: 0.9;
                    display: flex;
                    align-items: center;
                    border-right: 1px #eeeeee solid;
                    span{
                        width: 40/$font;
                        height: 40/$font;
                        border-radius: 50%;
                        background: #1675fd;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    div{
                        padding-left: 5/$font;
                    }
                }
                .content_right{
                    flex: 1;
                    padding-left: 10/$font;
                    .header_table{
                        tr{
                            height: 25/$font;
                        }
                    }
                }
            }
        }
        .body{
            padding: 0 26/$font;
            .body_title{
                margin-bottom: 10/$font;
                font-size: 16/$font;
                font-weight: bolder;
            }
            .body_table{
                tr{
                    height: 38/$font;
                    td:nth-child(1){
                        // width: 50/$font;
                    }
                }
            }
        }
        .bottom{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            a:nth-child(1){
                margin-bottom: 15/$font;
            }
            a{
                display: inline-block;
                padding: 10px;
                background: #1675fd !important;
                border-radius: 7px;
                color: #Fff;
                width: 40%;
                text-align: center;
            }
        }
    }
}