$font:1070/10rem;
.root{
  :global{
    .adm-list-item-content{
      border: none;
      padding-right: 0;
      a.adm-list-item:active:not(.adm-list-item-disabled)::after{
        display: none !important;
      }
      // .adm-list-item-content-main{
      //   padding: 0;
      // }
    }
    .adm-list-item{
      padding-left: 0;
      // height: 114/$font;
      //       line-height: 114/$font;
    }
    
    .adm-list-body{
      border-top: none;
      border-bottom: none;
    }
  }
}