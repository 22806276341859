$font:375/10rem;
.root{
    :global{
        height: 100%;
        background: #edeef0;
        overflow-y: scroll;
        .base_height{
            // height: -webkit-calc(100% - 1.6rem);
            // height: -moz-calc(100% - 1.6rem);
            // height: -ms-calc(100% - 1.6rem);
            // height: -o-calc(100% - 1.6rem);
            height: calc(100% - 1.6rem);
            overflow: auto;
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);
        }
        .height100{
            height: 100%;
            padding-bottom: 0;
        }
       .adm-tab-bar{
            position: fixed;
            bottom: 0;
            background: #fff;
            width: 100%;
            border-top: 1px #edeef0 solid;
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);
            z-index: 999;
            box-sizing: border-box;
            // display: none;
            .adm-tab-bar-wrap{
                height: 1.6rem;
            }
            
       }
       .adm-tab-bar-item-active{
            path,rect{
                stroke: var(--adm-color-primary);
                // stroke-width: 4;
            }
        }
        .hidden_tab{
            display: none;
        }
        .show_tab{
            display: block;
        }
    }
}