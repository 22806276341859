$font:1080/10rem;
.root{
  :global{
    height: 42/$font;
    display: flex;
    align-items: center;
    .body_center_the_divider{
      height: 0;
      border-top: 1px #d0d0d0 dashed;
      position: relative;
      width: 100%;
      padding-left: 21/$font;
      padding-right: 21/$font;
    }
    .body_center_the_divider::after{
      content: '';
      position:absolute;
      height: 42/$font;
      width: 21/$font;
      background: var(--background);
      left: 0;
      top: -21/$font;
      border-top-right-radius: 42/$font;
      border-bottom-right-radius: 42/$font;
    }
    .body_center_the_divider::before{
      content: '';
      position:absolute;
      height: 42/$font;
      width: 21/$font;
      background: var(--background);
      right: 0;
      top: -21/$font;
      border-top-left-radius: 42/$font;
      border-bottom-left-radius: 42/$font;
    }
  }
}