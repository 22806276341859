$font:1070/10rem;
.root{
  :global{
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    .header{
      background: #F5F6F8;
      height: 140/$font;
      line-height: 140/$font;
      padding: 0 55/$font;
      font-size: 48/$font;
      display: flex;
      justify-content: space-between;
      align-items: center;
      :nth-child(1){
        color: #9A9B9D;
      }
      :nth-child(2){
        color: #3F82E2;
      }
    }
    .adm-list-item{
      padding-left: 0 !important;
      .adm-list-item-content{
        padding: 0 55/$font;
      }
    }
  }
}