$font:375/10rem;
.root{
    :global{
        height: 100%;
        background: #fff;
        padding: 5/$font 5/$font;
       h5{
        text-align: center;
        font-size: 18/$font;
        line-height: 35/$font;
       }
       p{
        font-size: 15/$font;
        line-height: 28/$font;
       }
       h1{
        font-size: 12/$font;
        padding-left: 13/$font;
        font-weight: 300;
        line-height: 19/$font;
       }
       .img_box{
            display: flex;
            justify-content: center;
            margin-bottom: 10/$font;
        >span{
            margin-right: 10/$font;
            text-align: center;
            margin-bottom: 10/$font;
            margin-top: 10/$font;
            img{
                width: 150/$font;
                display: block;
            }
        }
       }
    }
}